import { CookieAttributes } from 'js-cookie';

const tokenExpiresTimeSeconds = process.env.ACCESS_TOKEN_EXPIRE_TIME_SECONDS || 1800; // 30 minutes
const refreshTokenExpiresTimeSeconds = process.env.REFRESH_TOKEN_EXPIRE_TIME_SECONDS || 604800; // 7 days

const cookieOptions: CookieAttributes = { secure: true, sameSite: 'strict' };

const tokenCookieOptions = {
  ...cookieOptions,
  expires: new Date(new Date().getTime() + (tokenExpiresTimeSeconds as number) * 1000),
};

const refreshTokenCookieOptions = {
  ...cookieOptions,
  expires: new Date(new Date().getTime() + (refreshTokenExpiresTimeSeconds as number) * 1000),
};

export { tokenCookieOptions, refreshTokenCookieOptions };

export default cookieOptions;
