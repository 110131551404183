import { refreshTokenAction } from './auth';
import { IMetadata, ISelectBoxProps } from './commonType';
import axios from 'axios';
import fetch from 'isomorphic-unfetch';
import cookie from 'js-cookie';
import moment from 'moment';
import Router from 'next/router';
import { toast } from 'react-toastify';

const _ = require('lodash');

const { API_URL } = process.env;

function logout(isServer = false) {
  cookie.remove('token');
  cookie.remove('refreshToken');
  // to support logging out from all windows
  if (!isServer) {
    window.localStorage.setItem('logout', Date.now().toString());
    Router.push('/login');
  }
}

export const fetcher = (url: string, options: any, controller?: AbortController) => {
  const accessToken = cookie.get('token');
  const fetchOptions = { ...options };
  if (accessToken) {
    _.set(fetchOptions, 'headers.authorization', `Bearer ${accessToken}`);
  }
  if (controller?.signal) {
    fetchOptions.signal = controller.signal;
  }
  return fetch(url, fetchOptions)
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      }
      const result = await res.clone().json();

      if (result?.type === 'TOKEN_EXPIRED' || result?.data?.type === 'TOKEN_EXPIRED') {
        const token = await refreshTokenAction();
        _.set(options, 'headers.authorization', `Bearer ${token}`);
        const response = await fetch(url, options);
        return response.json();
      }

      if (result?.type === "UNAUTHORIZED" || result?.message === 'Access Session invalid.') {
        const urlRedirect = '/login';
        cookie.remove('token');
        cookie.remove('refreshToken');
        if(typeof window !== 'undefined') {
          window.location.href = urlRedirect
        }
      }

      return res.json();
    })
    .catch((error) => error);
};

export const fetchApi = async (url: string, options: any) => {
  const accessToken = cookie.get('token');
  if (accessToken) {
    _.set(options, 'headers.authorization', `Bearer ${accessToken}`);
  }
  return fetch(url, options)
    .then(async (res) => {
      if (res.ok) {
        return res;
      }
      const result = await res.clone().json();
      if (result?.type === 'TOKEN_EXPIRED' || result?.data?.type === 'TOKEN_EXPIRED') {
        const token = await refreshTokenAction();
        _.set(options, 'headers.authorization', `Bearer ${token}`);
        return fetch(url, options);
      }

      if (result?.type === "UNAUTHORIZED" || result?.message === 'Access Session invalid.') {
        const urlRedirect = '/login';
        cookie.remove('token');
        cookie.remove('refreshToken');
        if(typeof window !== 'undefined') {
          window.location.href = urlRedirect
        }
      }
      return res;
    })
    .catch((error) => error);
};

export const fetchApiWithoutToken = async (url: string, options: any) => fetch(url, options);

export const fetchDirect = async (url: string, options: any) => {
  const { headers } = options;
  delete headers.host;
  delete headers['content-length'];
  return axios
    .request({
      baseURL: API_URL,
      method: options.method,
      url: `/${url}`,
      data: options.body,
      headers,
    })
    .then((response) => {
      const result = response.data;
      const data = result?.data || result;

      if (!result?.pagination) {
        return {
          code: response.status,
          data,
          message: result.message,
        };
      }
      return {
        code: response.status,
        data: result,
        message: result.message,
      };
    })
    .catch((error) => {
      if (error.response) {
        return {
          code: error.response.status,
          data: error.response.data,
          message: error.response.message,
        };
      }
      return {
        code: 400,
        data: {
          code: 400,
          message: 'Request failed!',
        },
        message: 'Request failed!',
      };
    });
};

export const ipfsLink = (hash: string) => `https://gateway.pinata.cloud/ipfs/${hash}`;

// TODO: when format is final/approved move this to utils-common. Also apply for WEB.
export const formatDate = (date: Date | string, timeZone?: string) => {
  const dateOptions: Record<string, any> = {
    hour12: true,
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  if (timeZone) dateOptions.timeZone = timeZone;
  return new Date(date).toLocaleString(undefined, dateOptions).replaceAll(',', '');
};

export const uploadIPFSFile = async ({
  file,
  username,
  onStart,
  onProgress,
  onComplete,
}: {
  file: File;
  username: string;
  onStart?: Function;
  onProgress: Function;
  onComplete: Function;
}) => {
  const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
  if (onStart) onStart();

  const data = new FormData();
  data.append('file', file);
  const metadata = JSON.stringify({
    name: file.name,
    keyvalues: {
      type: file.type,
      uploader: username,
      source: 'wa-web',
    },
  });
  data.append('pinataMetadata', metadata);
  const pinataOptions = JSON.stringify({
    cidVersion: 0,
  });
  data.append('pinataOptions', pinataOptions);
  try {
    const response = await axios(url, {
      method: 'POST',
      headers: {
        pinata_api_key: process.env.PINATA_API_KEY,
        pinata_secret_api_key: process.env.PINATA_API_SECRET,
      },
      data,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        onProgress(progress);
      },
    });
    if (response.status === 200) {
      const result = await response.data;
      onComplete(result.IpfsHash);
    } else {
    }
    return response;
  } catch (error) {
    return error;
  }
};

export function clipboardCopy(text: string) {
  let success = false;

  if (typeof window !== 'undefined') {
    // Use the Async Clipboard API when available. Requires a secure browsing
    // context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch((err) => {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    const span = document.createElement('span');
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = 'pre';

    // Add the <span> to the page
    document.body.appendChild(span);

    // Make a selection object representing the range of text selected by the user
    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      // Copy text to the clipboard
      try {
        success = window.document.execCommand('copy');
      } catch (err) {
        toast.error('Copy text to the clipboard Failed');
      }

      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
}

interface Ledger {
  entry: Record<string, any>;
  account: string;
}
export function findTokenBalance(ledger?: Ledger, typeId?: number) {
  if (!ledger) return 0;

  const { tokens = [] } = ledger.entry;
  return tokens.reduce(
    (total: number, { tokTypeId, currentQty }: Record<string, any>) =>
      // if (typeId === formatter.hex2int(tokTypeId)) {
      //   return total + formatter.hex2int(currentQty);
      // }

      total,
    0
  );
}

export function findCurrencyBalance(ledger?: Ledger, typeId?: number): string | number {
  if (!ledger || !typeId) return 0;

  const ccys = ledger.entry?.ccys ?? [];
  // const ccy = ccys.filter((cy: Record<string, any>) => formatter.hex2int(cy.ccyTypeId) === typeId);
  // if (ccy[0]) {
  //   const { unit, balance, decimals = 0 } = ccy[0];
  //   const value = formatter.convertUnitValue(unit, balance);
  //   return formatter.formatCurrency(value, decimals);
  // }

  return 0;
}

/**
 * Parse VCS serial block format
 * @param serialBlock string
 * @returns project.serialStart string
 * @returns project.serialEnd string
 * @returns project.country string
 * @returns project.projectId string
 * @returns project.vintageStart string
 * @returns project.vintageEnd string
 */
export function parseVCSFormat(serialBlock: string): { [key: string]: string } | undefined {
  const vcsSerialRegex =
    /^\w+-(?<serialStart>\w+)-(?<serialEnd>\w+)-\w+-\w+-\w+-\w+-(?<country>\w+)-\w+-(?<projectId>\w+)-(?<vintageStart>\w+)-(?<vintageEnd>\w+)-\w+$/;
  if (vcsSerialRegex.test(serialBlock)) {
    const match = vcsSerialRegex.exec(serialBlock);
    return match?.groups;
  }
  return undefined;
}

/**
 * Parse GS serial block format
 * @param serialBlock string
 * @returns project.serialStart string
 * @returns project.serialEnd string
 * @returns project.country string
 * @returns project.projectId string
 * @returns project.vintage string
 */
export function parseGSFormat(serialBlock: string): { [key: string]: string } | undefined {
  const vcsSerialRegex =
    /^\w+-\w+-(?<country>\w+)-(?<projectId>\w+)-\w+-(?<vintage>\w+)-\w+-(?<serialStart>\w+)-(?<serialEnd>\w+)$/;
  if (vcsSerialRegex.test(serialBlock)) {
    const match = vcsSerialRegex.exec(serialBlock);
    return match?.groups;
  }
  return undefined;
}

export const onDownload = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  const title = fileName === '' ? `CSV Report` : fileName;

  link.href = url;
  link.setAttribute('download', `${title}`);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadCSVFile = (data, fileName = '') =>
  fetch('/api/csv', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data, fileName }),
  })
    .then((resp) => resp.blob())
    .then((blob) => onDownload(blob, fileName));

export const tokenQtyMultiplier = () => (process.env.WEB_COMPANY_NAME === 'XYZ' ? 1000 : 1);
export const unixToUTCDate = (timestamp: number) => new Date(timestamp * 1000).toUTCString();

export const groupBatchesByBatchId = (batches: Array<any>, tokens: Array<any>) =>
  batches?.reduce(
    (projects: Record<string, any>, batch: Record<string, any>) =>
      // const batchId = formatter.hex2int(batch.batchId);
      // const batchSTs = tokens.filter((token: { batchId: any }) => formatter.hex2int(token.batchId) === batchId);

      // const stIds = batchSTs.map((token: { stId: any }) => formatter.hex2int(token.stId));
      // const qty = batchSTs.reduce((sum: number, st: Record<string, any>) => {
      //   const total = sum + formatter.hex2int(st.currentQty);
      //   return total;
      // }, 0);

      // const currentQty = qty / tokenQtyMultiplier();
      // const createdAt = batch.mintedTimestamp ? unixToUTCDate(batch.mintedTimestamp) : '';
      // const project: Record<string, any> = batch.metaData;

      // if (projects[batchId]) {
      //   return {
      //     ...projects,
      //     [batchId]: {
      //       ...projects[batchId],
      //       stIds: [...projects[batchId].stIds, ...stIds],
      //     },
      //     currentQty: projects[batchId].currentQty + qty,
      //   };
      // }

      // const startVintageYear = project.DATE_VINTAGE_START?.substr(project.DATE_VINTAGE_START.length - 4, 4);

      // NOTE: disabling this for now until business confirms if this is the official approach.
      // NOTE: if vintage start month is before October, then use start year for vintage.
      // let vintageYear = startVintageYear;
      // const startVintageMonth = project.DATE_VINTAGE_START?.substr(2, 2);
      // if (Number(startVintageMonth) >= 10) {
      //   vintageYear = project.DATE_VINTAGE_END?.substr(project.DATE_VINTAGE_END.length - 4, 4);
      // }
      // return {
      //   ...projects,
      //   [batchId]: {
      //     batchId,
      //     tokenTypeId: formatter.hex2int(batch.tokTypeId),
      //     stIds,
      //     project: {
      //       ...project,
      //       vintage: startVintageYear ?? '',
      //       countryName: projects?.countryNames?.[String(project.LIST_COUNTRY)] ?? '',
      //     },
      //     currentQty,
      //     createdAt,
      //   },
      // };
      1,
    {}
  );

export const downloadFile = (data: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const havePermission = (permission: string, permissions: string[]) => permissions.includes(permission);

export const handleListDataSelectBox = (listData: IMetadata[]) => {
  const data: ISelectBoxProps[] = [];
  data.push({ value: '', label: 'Please select' });
  for (let i = 0; i < listData?.length; i++) {
    data.push({ value: listData[i].code, label: listData[i].label });
  }
  return data;
};

export const handleListDataSelectBoxWithout = (listData: IMetadata[]) => {
  const data: ISelectBoxProps[] = [];
  for (let i = 0; i < listData?.length; i++) {
    data.push({ value: listData[i].code, label: listData[i].label });
  }
  return data;
};

interface IDocument {
  documentFile: any;
  documentName: string;
  documentType: string;
  documentRemark: string;
}

export const uploadDocument = async (token, id, values: IDocument) => {
  try {
    const form = new FormData();
    form.append('document_name', values.documentName);
    form.append('document_type', values.documentType);
    form.append('document_remarks', values.documentRemark);
    form.append('file', values.documentFile[0]);
    const result = await fetch(`${process.env.API_URL}/api/documentTracker/upload/${id}`, {
      method: 'POST',
      body: form,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (result.ok) {
      const response = await result.json();
      return response?.uploadedDocument;
    }
    toast.error('Have some errors when uploading file!');
    return null;
  } catch (error) {
    toast.error('Have some errors when uploading file!');
    return null;
  }
};
export const shortWalletAddress = (wallet: string) => `${wallet.slice(0, 6)}...${wallet.slice(wallet.length - 4)}`;

// export const downloadImage = (linkDownload: string) => {
//   const link = document.createElement('a');
//   link.href = linkDownload;
//   link.download = 'Download';
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

export const transformArray = (arrayData: any) => (Array.isArray(arrayData) ? arrayData : []);

export const downloadZipFile = (res: any, fileName: string, isView?: boolean) => {
  const data = URL.createObjectURL(new Blob([res], { type: 'application/zip' }));
  const link = document.createElement('a');
  link.href = data;
  if (!isView) {
    link.setAttribute('download', fileName);
  }
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const stringtoBuffer = (str: string) => {
  const buf = new ArrayBuffer(str.length); // 2 bytes for each char
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

const themes = {
  BRAND1: {
    brandColor: '#2CAB77',
    brandRbgColor: '44,171,119',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#000',
  },
  BRAND2: {
    brandColor: '#E7313A',
    brandRbgColor: '231,49,58',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#FFFFFF',
  },
};

export function getTheme(companyName: 'BRAND2' | 'BRAND1'): {
  brandColor: string;
  brandRbgColor: string;
} {
  return themes[companyName];
}

export default {
  themes,
  getTheme,
};

export function timestampToUtc(timestamp: number) {
  return new Date(timestamp * 1000).toUTCString();
}

export const addCommas = (num: number | string) => {
  const str = num?.toString();

  const hasDecimal = str?.includes('.');

  if (!hasDecimal) {
    return str?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const [integerPart, decimalPart] = str?.split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedIntegerPart}.${decimalPart}`;
};

export const removeNonNumeric = (num: number | string) => num?.toString().replace(/[^0-9.]/g, '');

export const convertArraybufferToJson = (data: ArrayBuffer) => {
  const uint8Array = new Uint8Array(data);
  const jsonString = new TextDecoder().decode(uint8Array);
  const jsonObject = JSON.parse(jsonString);
  return jsonObject;
};
const formatLabel = (input: string) => {
  const withoutUnderscore = input.replace(/_/g, ' ');
  return withoutUnderscore.toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
};
export const handleListMintedFor = (listData: any[]) => {
  const data: ISelectBoxProps[] = [];
  data.push({ value: null, label: 'Please Select' });

  for (let i = 0; i < listData?.length; i += 1) {
    const formattedLabel = formatLabel(listData[i]);
    data.push({ value: listData[i], label: formattedLabel });
  }
  return data;
};

export const downLoadPdfFile = (res: any, fileName: string, isView?: boolean) => {
  const data = URL.createObjectURL(new Blob([res], { type: 'application/pdf' }));
  const link = document.createElement('a');
  link.href = data;
  link.target = '_blank';
  if (!isView) {
    link.setAttribute('download', fileName);
  }
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const convertTwoDecimal: (value: string) => string = (value: string) => {
  const numericValue = value.replace(/[^0-9.]/g, '');
  const limitedDecimalValue = numericValue.replace(/(\.\d{2})\d+?$/, '$1');

  return limitedDecimalValue;
};

export const changeToDateTimeUTC: (date: string, time: string) => string = (date: string, time: string) => {
  const inputDate = new Date(`${date} ${time}`);
  const timeUTC = moment(inputDate.toUTCString(), 'ddd, DD MMM YYYY HH:mm:ss [GMT]').format('DD/MM/YYYY HH:mm:ss');
  return timeUTC;
};

export const changeToTimeUTC: (value: string) => string = (time: string) => {
  const localTime = time;
  const localMoment = moment(localTime, 'HH:mm');
  const utcMoment = localMoment.utcOffset(0);
  const utcTime = utcMoment.format('HH:mm');
  return utcTime;
};
